import React, { Component } from "react";
import { Form, Input, Label, Checkbox, Field } from "../styleguide/Form";
import Button from "../styleguide/Button";
import receivers from "../utils/receivers_new";
import Search from "./Downshift";
import Text from "../styleguide/Text";
import Status from "../styleguide/Status";
import config from "../utils/config";
import { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";

const SuccessForm = ({setSentForm}) => {

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;


  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [region, setrRegion] = useState("");
  const [commune, setCommune] = useState("");
  const [county, setCounty] = useState("");
  const [consentEmail, setConsentEmail] = useState("");
  const [consentBasic, setConsentBasic] = useState("");
  const [formError, setFormError] = useState("");

  const handleSelect = (region, commune, county) => {
    setrRegion(region);
    setCommune(commune);
    setCounty(county);
  };

  const storeData = async () => {
      await fetch("/.netlify/functions/store", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        municipality: commune
      }),
    }).then((response) => {
      console.log(response);
        if(response.status == '200'){
          console.log("response with status")
            setSentForm(email);
        }else if(response.statusCode == '200'){
            console.log("response with status code")
            setSentForm(email);
        }else{
          let errorText ='Noe gikk galt ved innsending av skjema. Vennligst prøv igjen ved å oppdatere nettleservinduet, eller vent noen minutter før du prøver på nytt.';
          setFormError(errorText);
        }
    },
    (error) => {
      console.log('FAILED...', error);
    });
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

      storeData();
      const from_name = "Stendi";
      const msg = `
            \n Name : ${name},
            \n Phone : ${phone},
            \n Email : ${email},
            \n Region : ${region},
            \n Kommune : ${commune},
            \n County : ${county},
            \n Consent:basic : ${consentBasic},
            \n Consent:email : ${consentEmail} `;

            var templateParams = {
              from_name: name,
              to_name: from_name,
              message: msg
            };

            await emailjs.send(serviceId, templateId, templateParams).then(
            (response) => {
              if( response.status == '200'){
                setSentForm(consentEmail);
                console.log('SUCCESS!', response.status, response.text);
              }else{
                let errorText ='Noe gikk galt ved innsending av skjema. Vennligst prøv igjen ved å oppdatere nettleservinduet, eller vent noen minutter før du prøver på nytt.';
                setFormError(errorText);
              }


            },
            (error) => {
              console.log('FAILED...', error);
            },
          );
        }

  useEffect(() => {
    emailjs.init("8dGUDlEByZLtjyBls");
    // getData();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Field>
        <Label htmlFor="name">
          <span>
            Navn <span className="required">*</span>
          </span>
        </Label>
        <Input
          type="text"
          label="Navn"
          placeholder="Skriv inn ditt fulle navn"
          name="name"
          id="name"
          required
          withLabelSpace
          onChange={(e) => setName(e.target.value)}
        />
      </Field>

      <Field>
        <Label htmlFor="phone">
          <span>
            Telefonnummer <span className="required">*</span>
          </span>
        </Label>
        <Input
          type="text"
          label="Telefonnummer"
          placeholder="Skriv inn ditt telefonnummer"
          name="phone"
          id="phone"
          required
          withLabelSpace
          onChange={(e) => setPhone(e.target.value)}
        />
      </Field>
      <Field>
        <Label htmlFor="email">
          <span>
            E-post <span className="required">*</span>
          </span>
        </Label>
        <Input
          type="email"
          label="E-post"
          placeholder="Skriv inn din e-postadresse"
          name="email"
          id="email"
          required
          withLabelSpace
          onChange={(e) => setEmail(e.target.value)}
        />
      </Field>
      <Field>
        <Label htmlFor="county">
          <span>
            Kommune <span className="required">*</span>
          </span>
        </Label>
        <Search handleSelect={handleSelect} items={receivers} />
      </Field>

      <Field>
        <Checkbox
          required={true}
          onChange={(e) => setConsentBasic(e.target.value)}
        >
          Jeg godtar at Stendi oppbevarer de oppgitte personopplysningene i tråd
          med{" "}
          <a
            target="_blank"
            href="https://stendi.no/personvernerklaeringer"
            rel="noopener noreferrer"
          >
            personvernerklæringen
          </a>
          , og at Stendi kan kontakte meg per telefon og/eller e-post.{" "}
          <span className="required">*</span>
        </Checkbox>
      </Field>
      <Field>
        <Checkbox onChange={(e) => setConsentEmail(e.target.value)}>
          Jeg ønsker å motta relevante nyhetsbrev fra Stendi Fosterhjemtjenester
        </Checkbox>
      </Field>
      <Field>
        <Text>
          <Text element="span" color="primary">
            *
          </Text>
          Feltet må fylles ut.
        </Text>
      </Field>
      {formError && (
        <Field>
          <Status>{formError}</Status>
        </Field>
      )}
      <Field>
        <Button type="submit" fullWidth>
          Send
        </Button>
      </Field>
    </Form>
  );
};

export default SuccessForm;